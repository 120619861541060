<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row margin-bottom-20">
                <div class="col-xs-12 col-md-3">
                    <h1 class="h2 margin-0">
                        <i class="las la-chart-line margin-bottom-5" ></i>
                        Dashboard
                    </h1>
                </div>
                <div class="col-xs-12 col-md-3 text-right">
                  <ModelListSelect class="form-control" id="hospital-options" name="hospital-options" :list="hospitals" option-value="id" option-text="name" v-model="selectedHospital">Ospedale</ModelListSelect>
                </div>
                <div class="col-xs-12 col-md-6 text-right">
                    <button
                            class="btn btn-default btn-action-mobile btn-undo-mobile"
                            @click="$router.push({name:'research_project_detail', params: { research_project_id: $route.params.research_project_id}})"
                    >
                        <i class="las la-undo"></i>
                        <span class="button-text">Indietro</span>
                    </button>
                </div>
            </div>
            <div class="panel panel-default col-xs-12 col-md-3" style="padding: 0; margin: 10px">
                <div class="panel-heading text-center">Numero pazienti</div>
                <div class="panel-body">
                    <div class="col-xs-12 col-md-6 text-right">
                        <i class="las la-users"  style="font-size: 50px;"></i>
                    </div>
                    <div class="col-xs-12 col-md-6 ">
                        <span style="font-size: 30px;">{{dashboard.count_patients}}</span>
                    </div>
                </div>
            </div>
            <div class="panel panel-default col-xs-12 col-md-3" style="padding: 0; margin: 10px">
                <div class="panel-heading text-center">Numero Follow-up</div>
                <div class="panel-body">
                    <div class="col-xs-12 col-md-6 text-right">
                        <i class="las la-notes-medical"  style="font-size: 50px;"></i>
                    </div>
                    <div class="col-xs-12 col-md-6 ">
                        <span style="font-size: 30px;">{{dashboard.count_follow_up}}</span>
                    </div>
                </div>
            </div>
            <div class="panel panel-default col-xs-12 col-md-3" style="padding: 0; margin: 10px">
                <div class="panel-heading text-center">Numero Procedure Ablation</div>
                <div class="panel-body">
                    <div class="col-xs-12 col-md-6 text-right">
                        <i class="las la-users"  style="font-size: 50px;"></i>
                    </div>
                    <div class="col-xs-12 col-md-6 ">
                        <span style="font-size: 30px;">{{dashboard.count_ablation_procedure}}</span>
                    </div>
                </div>
            </div>
            <div class="panel panel-default col-xs-12 col-md-3" style="padding: 0; margin: 10px">
                <div class="panel-heading text-center">Età media pazienti (anni)</div>
                <div class="panel-body">
                    <div class="col-xs-12 col-md-6 text-right">
                        <i class="las la-user-injured"  style="font-size: 50px;"></i>
                    </div>
                    <div class="col-xs-12 col-md-6 ">
                        <span style="font-size: 30px;">{{dashboard.age_avg.toFixed(2)}}</span>
                    </div>
                </div>
            </div>

        </div>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import {getDashboardData} from "../api";
    import {getAllHospital} from "../api";
    import {ModelListSelect} from "vue-search-select";

    export default {
        name: "Dashboard",
        components: { ContainerWithMenu, ModelListSelect},
        data: function() {
            return {
                dashboard: {},
                loading: false,
                hospitals: [],
                allHospitalOption: {
                  id: 0,
                  name: "Tutti gli ospedali"
                },
                selectedHospital: null
            };
        },
        mounted() {
            this.selectedHospital = this.allHospitalOption
            this.getDashboardData()
            this.getHospitals()
        },
        methods: {
            async getDashboardData(selectedHospital = 0) {
                this.loading = true;
                try {
                    this.dashboard = await getDashboardData(this.$route.params.research_project_id, selectedHospital);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }


            },
            async getHospitals() {
              this.loading = true;
              try {
                this.hospitals = await getAllHospital()
                this.hospitals.unshift(this.allHospitalOption)
                console.log(this.hospitals)
                this.loading = false
              } catch (e) {
                this.loading = false;
                console.log(e)
              }
          }
        },
        watch: {
          selectedHospital: function(value) {
            console.log(value)
            this.getDashboardData(value.id)
          }
        }
    };
</script>
